<template>
  <section class="main-login">
    <div class="main-box flexed">
      <div class="login-logo-box">
        <img src="../../assets/images/logo_1080.png" alt="抖工科技">
      </div>
      <Card class="login-card" icon="log-in" :bordered="false">
        <span slot="title">
            请填写注册信息
        </span>
        <div class="form-con">
          <Form ref="loginForm" :model="loginForm" :rules="loginRules">
            <FormItem prop="adminName">
              <Input size="large" class="login_input" v-model="loginForm.adminName" autocomplete="off" placeholder="请输入您的姓名"></Input>
              <Input size="large" class="login_input" v-model="adminNameTmp" placeholder="请输入您的姓名" v-show="false"></Input>
            </FormItem>
            <FormItem prop="password">
              <Input size="large" class="login_input" type="password" v-model="passwordTmp" placeholder="设置登录密码,不少于8个字符" v-show="false"></Input>
              <Input size="large" class="login_input" type="password" v-model="loginForm.password" autocomplete="off"
                     placeholder="设置登录密码,不少于8个字符"></Input>
            </FormItem>
            <FormItem prop="companyName">
              <Input size="large" class="login_input" v-model="loginForm.companyName" placeholder="请输入您的企业名称">
              </Input>
            </FormItem>
            <FormItem style="margin-top: -12px;margin-bottom: 7px;">
              <Checkbox v-model="checkbox" @on-change="registerCheck" style="color:#515a6e;">
                <span>我已阅读并同意</span>
                <a class="link-box" target="_blank" href="http://v.dougongapp.com/userAgreement.html">《抖工服务协议》</a>
              </Checkbox>
            </FormItem>
            <FormItem>
              <Button
                :disabled="checkTrue"
                :style="buttonStyle"
                :loading="registerLoading"
                size="large"
                class="button-login"
                @click="registerSubmit('loginForm')"
                type="primary" long>
                <span class="button-inner-span" v-if="!registerLoading">完成注册</span>
                <span v-else>Loading...</span>
              </Button>
            </FormItem>
          </Form>
        </div>
      </Card>
    </div>

    <!-- 账号审核中... -->
     <Modal
      class="custom-modal"
      :mask-closable="false"
      v-model="accountAuditing"
      :title="'提示'"
      ok-text="确定"
      @on-ok="closeAuditingModal"
      @on-cancel="closeAuditingModal"
    >
      <!-- 测试 -->
      <div class="account-auditing-tips">
        您的注册账号正在审核中，商务人员会与您联系，请保持电话畅通，谢谢！
      </div>
    </Modal>



  </section>
</template>

<script>
  export default {
    data() {
      return {
        adminNameTmp: "",
        passwordTmp: "",
        // 完成注册按钮状态
        registerLoading: false,
        // 复选框状态
        checkbox: false,
        // 表单对象
        loginForm: {
          adminName: "",
          password: "",
          companyName: "",
        },
        // 按钮的样式
        buttonStyle: {
          color: '#fff'
        },
        // 按钮状态
        checkTrue: true,
        // 表单验证
        loginRules: {
          adminName: [{required: true, message: '姓名不能为空'}],
          password: [
            {required: true, message: '密码不能为空'},
            {type: "string", min: 8, message: "密码不少于8位"},
            {type: "string", max: 20, message: "密码不大于20位"},
          ],
          companyName: [{required: true, message: '企业名称不能为空'}]
        },
        registerObj: {
          phone: this.$route.params.phone,
          code: this.$route.params.code
        },

        // 账号审核中
        accountAuditing:false,
      }
    },
    created() {

    },
    mounted() {
      this.clearInput();
    },
    methods: {
      // 复选框状态切换
      registerCheck() {
        if (this.checkbox == true) {
          this.checkTrue = false;
        } else {
          this.checkTrue = true;
        }
      },
      // 注册跳转
      registerSubmit(name) {
        this.$refs[name].validate(valid => {
          if (valid) {
            this.registerLoading = true;
            this.registerObj = {
              phone: this.$route.params.phone,
              code: this.$route.params.code
            }
            var obj = {...this.loginForm, ...this.registerObj};
            this.$axios.post(`${this.apiUrlCros}/sapi/v3/common/register`, obj).then(res => {
              // if ((res.data.code == "200" || res.data.code == "0" )&& res.data.data != null) {
                if (res.data.code == "200" || res.data.code == "0") {
                // this.$Message.success('注册成功');


                // this.$Message.success('您的注册账号正在审核中，商务人员会与您联系，请保持电话畅通，谢谢！');

                this.accountAuditing=true

                // this.common.setAuth(res.data.resData);

                // this.$router.push({name: 'controlBooth'});

                this.registerLoading = true;
              } else {

                this.accountAuditing=false

                this.$Message.error(res.data.msg);
                this.registerLoading = false;
                this.$router.push({name: "registerEnterprise"});
                this.common.delStorage("phone");
              }
            })
              .catch(err => {
                this.$Message.error(err.message);
                this.registerLoading = false;
                this.$router.push({name: "registerEnterprise"});
              })
          }
        })
      },

       // 关闭 正在审核中的 弹框
      closeAuditingModal(){
        this.accountAuditing=false;
        //弹窗关闭之后,跳转到登录页面
        this.$router.push({name: 'controlBooth'});
      },

      
      clearInput() {
        this.loginForm.adminName = "";
        this.loginForm.password = "";
      },
      returnHome() {
        this.$router.push({name: "home"})
      },
      toProtocolPage(type) {
        this.common.toProtocolPage(type);
      }
    },
  }
</script>

<style lang="stylus">
.custom-modal .ivu-modal-footer .ivu-btn-text
{
  display: none!important;
}
.account-auditing-tips{
  text-align:center;
}


.link-box
  text-decoration underline
</style>
